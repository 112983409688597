<script setup>
  import imageUrl from "#assets/kato.studio/malavan.jpg";
  // /assets/kato.studio/malavan.jpg
</script>
<template>
  <div class="bg-zinc-900 text-white">
    <!-- Main Container -->
      <div class="flex flex-col items-center justify-center min-h-screen py-12 md:py-18 fadeIn ">
        <div class="flex flex-col lg:flex-row items-center bg-zinc-800 rounded-lg px-12 py-12 max-w-4xl gap-8">
          <!-- Text Section -->
          <div class="flex-1 text-left ">
            <h1 class="text-4xl font-bold fadeIn fDelay-1" >
              HELLO THERE
            </h1>
            <!-- Contact Button -->
            <div class="__faded fadeIn fDelay-4 pb-6">
              <p class="font-semibold mb-1 text-zinc-500" >
                Get in Touch
              </p>
              <a href="https://zcal.co/kato-studio/30min" arial-label="Book Your Free Consultation CTA button" target="_blank" >
                <button class="inline-flex font-bold py-2.5 px-6 text-xl border border-primary stroke-white bg-primary hover:bg-primary-600 items-center focus:outline-none rounded mt-4 md:mt-0">
                  Book Your Free Consultation
                  <svg fill="none" class="w-6 h-full ml-1" stroke="inherit" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </button> 
              </a>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<style scoped>
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .fadeIn {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 0.5s ease-in-out forwards;
  }
  .fDelay-1 {
    animation-delay: 0.2s;
  }
  .fDelay-2 {
    animation-delay: 0.4s;
  }
  .fDelay-3 {
    animation-delay: 0.6s;
  }
  .fDelay-4 {
    animation-delay: 0.8s;
  }
</style>
